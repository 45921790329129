<template>
  <v-container class="d-flex flex-column align-center justify-center text-center" style="height: 100vh;">
    <v-img
      src="https://via.placeholder.com/400x300?text=404+Not+Found"
      alt="404 Illustration"
      contain
      max-width="400"
    />
    <h1 class="display-1">
      404
    </h1>
    <h2 class="subtitle-1">
      Oops! The page you're looking for doesn't exist.
    </h2>
    <p class="body-1 mb-8">
      It might have been moved or deleted. Let's get you back on track.
    </p>
    <v-btn color="primary" class="ma-2" @click="goHome">
      Go to Home
    </v-btn>
    <v-btn
      outlined
      color="secondary"
      class="ma-2"
      @click="goBack"
    >
      Go Back
    </v-btn>
  </v-container>
</template>

<script lang="ts" setup>
const router = useRouter();

const goHome = () => {
  router.push('/');
};

const goBack = () => {
  router.go(-1);
};
</script>

<style scoped>
h1 {
  font-weight: bold;
}
</style>
